<template>
    <div>
        <v-card class="primary darken-2" style="" >
            <v-card-text class="py-1 ma-0 " style="font-size: 12px; ">
                <p class="white--text py-1 ma-0 subheading text-xs-center"><span>Fill in the required information to proceed.</span></p>
            </v-card-text>
        </v-card>

        <v-card color="white pa-3 " style="background-color: rgb(236, 243, 250)!important" >
            <b>Step 1/3: Your Identification Info</b>
            <v-layout wrap row>
                <v-flex xs12 md10 offset-md1>
                    <v-layout row>
                        <v-flex xs2 sm1>
                            <i class="teal--text fas fa-user fa-2x mt-3"> </i>                            
                        </v-flex>
                        <v-flex xs9 sm10>
                            <v-text-field class="pa-0 mt-3"
                                ref="id_name" v-model="idName"
                                label="Enter Your Names as on ID Card"
                                :rules="[v => !!v || 'Your Names as on ID Card is required']"
                                color="teal" required
                            ></v-text-field>
                        </v-flex>
                        <v-flex xs2 >
                            <v-btn @click.stop="$store.commit('activateDialog', true); id_name_help = true; getHelpMessage()" flat fab small outline color="black" class="my-4" style="height:20px; width: 20px" >
                                <i class="fas fa-question"> </i>                            
                            </v-btn>
                        </v-flex>
                    </v-layout>
                </v-flex>
                <v-flex xs12 md10 offset-md1>
                    <v-layout row>
                        <v-flex xs2 sm1>
                            <i class="teal--text fas fa-hashtag fa-2x mt-3"> </i>                            
                        </v-flex>
                        <v-flex xs9 sm10>
                            <v-text-field class="pa-0 mt-3"
                                ref="id_card_number" v-model="id_card_number"
                                label="Enter ID Card Number"
                                :rules="[v => !!v || 'Your ID Card Number is required']"
                                color="teal" required
                            ></v-text-field>
                        </v-flex>
                        <v-flex xs2 >
                            <v-btn @click.stop="$store.commit('activateDialog', true); id_card_number_help = true; getHelpMessage()" flat fab small outline color="black" class="my-4" style="height:20px; width: 20px" >
                                <i class="fas fa-question"> </i>                            
                            </v-btn>
                        </v-flex>
                    </v-layout>
                </v-flex>
                <v-flex xs12 md10 offset-md1>            
                    <v-layout row>
                        <v-flex xs2 sm1>
                            <i class="teal--text fas fa-calendar fa-2x mt-3"> </i>                            
                        </v-flex>
                        <v-flex xs9 sm10>
                            <v-text-field class="pa-0 mt-3"
                                ref="id_card_issue_date"
                                label="ID Card issue Date" v-model="idCardIssueDate"
                                type="date"
                                 color="teal" required
                            ></v-text-field>
                        </v-flex>
                        <v-flex xs2 >
                            <v-btn @click.stop="$store.commit('activateDialog', true); date_help = true; getHelpMessage()" flat fab small outline color="black" class="my-4" style="height:20px; width: 20px" >
                                <i class="fas fa-question"> </i>                            
                            </v-btn>
                        </v-flex>
                    </v-layout>
                </v-flex>
                <v-flex xs12 md10 offset-md1>            
                    <v-layout row>
                        <v-flex xs2 sm1>
                            <i class="teal--text fas fa-pen-to-square fa-2x mt-3"> </i>                            
                        </v-flex>
                        <v-flex xs9 sm10>
                            <v-textarea class="pa-0 mt-3"
                                name="input-7-1"
                                auto-grow v-model="jobDescription"
                                ref="job_description"
                                label="Your Profession(Job) Description"
                                :rules="[v => !!v || 'Your Job Description is required']"
                                background-color="grey lighten-4" color="teal"
                                rows="2" box required
                                row-height="20" 
                            ></v-textarea>
                        </v-flex>
                        <v-flex xs2 >
                            <v-btn @click.stop="$store.commit('activateDialog', true); job_description_help = true; getHelpMessage()" flat fab small outline color="black" class="my-4" style="height:20px; width: 20px" >
                                <i class="fas fa-question"> </i>                            
                            </v-btn>
                        </v-flex>
                    </v-layout>
                </v-flex>
            </v-layout>
            <div class="text-xs-center">
                <v-btn @click="joinNewGroup()" class="white--text elevation-6" type="submit" small round  color="teal" >
                    <span> Next</span>
                </v-btn>
            </div>
        </v-card>
    </div>
</template>


<script>
    import axios from 'axios'

    export default {

        data() {
            return {
                id_name_help: false,
                job_description_help: false,
                isDateRequired: false,
                id_card_number_help: false,
                date_help: '',
                idName: "",
                jobDescription: "",
                id_card_number: '',
                idCardIssueDate: '',

            }
        },
        mounted(){
            // executes these after the page has been mounted
            document.title = "PettyCash Njangi | Join New Group"
        },

        methods:{
            async joinNewGroup(){
                if(this.idName === ""){
                    this.$store.commit('setSnackBarMessage', "Please fill in the Your Names as on ID Card")
                    this.$store.commit('setSnackBarColor', "red darken-4")
                    this.$store.commit('activateSnackBar', true)
                    this.$refs.id_name.focus()  // this causes a an auto fucos to the element
                }
                else if(this.jobDescription === ""){
                    this.$store.commit('setSnackBarMessage', "Please input your Job Description")
                    this.$store.commit('setSnackBarColor', "red darken-4")
                    this.$store.commit('activateSnackBar', true)
                    this.$refs.job_description.focus()  // this causes a an auto fucos to the element
                }
                else if(this.id_card_number.length < 7 || this.id_card_number.length > 15){
                    this.$store.commit('setSnackBarMessage', "Please input a correct phone number.")
                    this.$store.commit('setSnackBarColor', "red darken-4")
                    this.$store.commit('activateSnackBar', true)
                    this.$refs.id_card_number.focus()  // this causes a an auto focus to the element
                }
                else {
                    this.$store.commit('setIsLoading', true)
                    let formData = new FormData()
                    formData.append('id_name', this.idName)
                    formData.append('id_card_number', this.id_card_number)
                    formData.append('id_card_issue_date', this.idCardIssueDate)
                    formData.append('job_description', this.jobDescription)
                
                    await axios
                        .post('/api/v1/njangi/join/new_group/', formData, {headers: {'Content-Type': 'multipart/form-data'}})
                        .then(response => {
                            this.$store.commit('setSnackBarMessage', response.data[0].text)
                            this.$store.commit('setSnackBarColor', response.data[0].color)
                            this.$store.commit('activateSnackBar', true)
                            if(response.data[0].id !== 0){
                                let router = this.$router
                                setTimeout(function(){
                                    router.push('/njangi/groups/new_group/two/'+response.data[0].id)
                                }, 4000)
                            }
                        })
                        .catch(error => {
                            if (error.response){
                                this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
                                this.$store.commit('setSnackBarColor', "red darken-4")
                                this.$store.commit('activateSnackBar', true)
                            }else{
                                this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
                                this.$store.commit('setSnackBarColor', "red darken-4")
                                this.$store.commit('activateSnackBar', true)
                            }
                        })
                    this.$store.commit('setIsLoading', false)
                }
            },

            getHelpMessage(){
                if (this.id_name_help){
                    this.id_name_help = false
                    this.$store.commit('setDialogTitle', "Your Names as on ID Card")
                    this.$store.commit('setDialogMessage', "Here you are required to input your Your Names as on ID Card.")
                    this.$store.commit('activateDialog', true)
                }else if (this.id_card_number_help){
                    this.id_card_number_help = false
                    this.$store.commit('setDialogTitle', "Your ID Card Number")
                    this.$store.commit('setDialogMessage', "This entails for you to input your ID Card number.")
                    this.$store.commit('activateDialog', true)
                }else if (this.date_help){
                    this.date_help = false
                    this.$store.commit('setDialogTitle', "Your ID Card Issue Date")
                    this.$store.commit('setDialogMessage', "This entails for you to input the date on which your ID Card was issued.")
                    this.$store.commit('activateDialog', true)
                }else if (this.job_description_help){
                    this.job_description_help = false
                    this.$store.commit('setDialogTitle', "Job Description")
                    this.$store.commit('setDialogMessage', "This entails for you to input your current job description, if in the private sector or public sector and your current position.")
                    this.$store.commit('activateDialog', true)
                }
            },
        },
    }
</script>

<style scoped>

</style>
